<template>
  <Portal
    :menus="portal"
    :resourceType="resourceType"
    :resourceServer="parameter.resourceServer"
    :resourceId="parameter.resourceId"
    :endpoint="parameter.endpoint"
    :firewall="firewall"
  ></Portal>
</template>

<script>
export default {
  props: {
    resourceType: String,
    parameter: Object,
  },
  components: {
    Portal: () =>
      import("cms_infrastructure_web/src/sdk/Admin/Portal/Portal.vue"),
  },
  data: function () {
    return {
      portal: {
        MarketPlace: [
          {
            name: "Parner Network",
            content: "components/ParnerNetwork/Portal-ParnerNetwork.vue",
          },
        ],
        Manufactory: [
          {
            name: "Manufactory",
            content: "components/Manufactory/Portal-Manufactory.vue",
          },
          {
            name: "Product",
            content: "components/Product/Portal-Product.vue",
          },
          {
            name: "Flow",
            content: "components/Flow/Portal-Flow.vue",
          },
        ],
        ProductTag: [
          {
            name: "Product Appearance Tag",
            content:
              "components/ProductAppearanceTag/Portal-ProductAppearanceTag.vue",
          },
        ],
        Receiver: [
          {
            name: "Receiver",
            content:
              "applications/user/j1/business/iot-hub/Receiver/IoTHub-Panel-Receiver.vue",
          },
          {
            name: "Setting",
            content:
              "applications/user/j1/business/iot-hub/Receiver/IoTHub-Panel-Receiver-Setting.vue",
          },
        ],
        Device: [
          {
            name: "Device",
            content:
              "applications/user/j1/business/iot-hub/Device/IoTHub-Panel-Device.vue",
          },
        ],
        User: [
          {
            name: "User",
            content:
              "applications/user/j1/business/iot-hub/User/IoTHub-Panel-User.vue",
          },
        ],
        "Health Record": [
          {
            name: "Blood Pressure",
            content: "IoTHub-Sample-Instance-BloodPressure",
          },
          {
            name: "Blood Glucose",
            content: "IoTHub-Sample-Instance-BloodGlucose",
          },
          {
            name: "ECG",
            content: "IoTHub-Sample-Instance-ECG",
          },
          {
            name: "Step",
            content: "IoTHub-Sample-Instance-Step",
          },
          {
            name: "Heart Rate",
            content: "IoTHub-Sample-Instance-HeartRate",
          },
          {
            name: "Sleep",
            content: "IoTHub-Sample-Instance-Sleep",
          },
          {
            name: "Teeth",
            content: "IoTHub-Sample-Instance-Teeth",
          },
        ],
      },
      firewall: [
        { label: "All", value: "Default" },
        { label: "Backend", value: "Backend" },
        { label: "Frontend", value: "Frontend" },
        { label: "Security", value: "Security" },
        { label: "Debug", value: "Debug" },
        { label: "Analysis", value: "Analysis" },
        { label: "Receiver", value: "Receiver" },
      ],
    };
  },
};
</script>
